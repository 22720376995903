import CookieIcon from "@mui/icons-material/Cookie";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { CookieConsent } from "react-cookie-consent";
import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import "./I18";
import { initI18 } from "./I18";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { LoadingScreen } from "./objects/DatabaseHandler";

import "firebase/compat/database";
import StripePaymentComponent from "./components/stripe/StripePaymentComponent";
import firebase from "firebase/compat/app";

const Admin = React.lazy(() => import("./pages/admin/Admin"));
const Faq = React.lazy(() => import("./pages/contact/Faq"));
const Contact = React.lazy(() => import("./pages/contact/Contact"));
const MiddlePage = React.lazy(() => import("./pages/booking/MiddlePage"));
const BookingPage = React.lazy(() => import("./pages/booking/BookingPage"));
const ToS = React.lazy(() => import("./pages/tos/ToS"));
const Cookies = React.lazy(() => import("./pages/tos/Cookies"));
const Company = React.lazy(() => import("./pages/contact/Company"));
const ConfirmBooking = React.lazy(
  () => import("./pages/booking/ConfirmBooking")
);
const Confirm = React.lazy(() => import("./pages/booking/ConfirmationPage"));
const Home = React.lazy(() => import("./pages/Home"));

function App() {
  return (
    <Router>
      <Routes>
        <Route
          element={
            <React.Suspense fallback={<LoadingScreen />}>
              <Layout />
            </React.Suspense>
          }
        >
          <Route path={"/admin"} element={<Admin />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/kontakta" element={<Contact />} />
          <Route path="/boka" element={<MiddlePage />} />
          <Route path={"/boka/:course"} element={<BookingPage />} />
          <Route path="/tos" element={<ToS />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/foretag" element={<Company />} />
          <Route path="/bekrafta" element={<ConfirmBooking />} />
          <Route path="/bekraftelse" element={<Confirm />} />
          <Route path="/home" element={<Home />} />
          <Route path="/hem" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/test" element={<Test />} />
        </Route>
      </Routes>
    </Router>
  );
}

function Test() {
  async function test() {
    const gtag = window["gtag"];

    await gtag("event", "conversion", {
      send_to: "AW-11111821255/8vU2CJLP4OoYEMffw7Ip",
      value: 1500.0,
      currency: "SEK",
      transaction_id: "test12",
    });
  }

  return (
    <div style={{ paddingTop: "10vh" }} className="">
      <div className="xl:w-1/2"></div>
    </div>
  );
}

function Layout() {
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 100 + 500 * Math.random());

    window.scrollTo(0, 0);
  }, [pathname]);

  const [langLoaded, setLangLoaded] = useState(false);

  useEffect(() => {
    async function loadLang() {
      await initI18();
    }
    if (!langLoaded) loadLang().then(() => setLangLoaded(true));
  }, []);

  if (!langLoaded) return <LoadingScreen />;

  if (loading) return <LoadingScreen />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: "100vh",
      }}
    >
      <CookieConsent
        location="bottom"
        buttonText="OK"
        cookieName="cookies_consent"
        style={{ background: "rgb(43,55,59)" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        <CookieIcon fontSize={"large"} style={{ color: "#6db228" }} />
        <label style={{ fontSize: "15px", fontWeight: "bold" }}>
          Cookies används för att stödja din upplevelse när du använder vår
          webbplats.
        </label>
      </CookieConsent>
      <Header />
      <Outlet />
      <Footer />
    </Box>
  );
}

export default App;
