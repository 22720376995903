import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

export async function initI18() {
  if (i18next.isInitialized) return true;

  await i18next
    .use(Backend)
    .use(initReactI18next)
    //        .use(LanguageDetector)
    .init({
      fallbackLng: "sv",
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      react: {
        bindI18n: "languageChanged",
        bindI18nStore: "",
        transEmptyNodeValue: "",
        transSupportBasicHtmlNodes: true,
        useSuspense: true,
      },
      backend: {
        loadPath: "/localized-files/{{lng}}/{{ns}}.json",
      },
    });

  return true;
}

export default i18next;
