import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import LanguageIcon from "@mui/icons-material/Language";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import i18 from "../I18";
import { Link } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import halkanLogo from "../media/halkan-Logo.webp";
import { useNavigate, useLocation } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const styles = {
    link: {
      fontWeight: "bold",
      fontFamily: "plus jakarta sans, sans-serif",
      color: "black",
      margin: 4,
      textDecoration: "none",
    },
  };
  useTranslation();
  const [width, setWidth] = useState(window.innerWidth);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(true);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  if (width <= 900) {
    return (
      <Box>
        <Accordion elevation={0} expanded={mobileOpen}>
          <AccordionSummary
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              backgroundColor: "#b3e0ff",
            }}
          >
            <Box
              style={{
                display: "flex",
                flex: 1,
                width: "100vw",
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Link to={"/"}>
                  <img
                    alt={"Halkan.se"}
                    src={halkanLogo}
                    style={{ width: 150 }}
                  />
                </Link>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flex: 1,
                  alignContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <LangMen />
                <MenuIcon onClick={() => setMobileOpen(!mobileOpen)} />
              </div>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            style={{
              backgroundColor: "#b3e0ff",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#b3e0ff",
              }}
            >
              <Link
                rel="canonical"
                onClick={() => setMobileOpen(!mobileOpen)}
                className={"link-header"}
                color={"success"}
                to={"/boka"}
                style={{
                  fontWeight: "800",
                  color: "green",
                  margin: 4,
                  textDecoration: "none",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {i18.t("navbar.book")}
              </Link>

              <Link
                rel={"canonical"}
                onClick={() => setMobileOpen(!mobileOpen)}
                className={"link-header"}
                to={"/kontakta" + ""}
                style={{
                  fontWeight: "800",
                  color: "black",
                  margin: 4,
                  textDecoration: "none",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {i18.t("navbar.contact")}
              </Link>
              <Link
                rel={"canonical"}
                onClick={() => setMobileOpen(!mobileOpen)}
                className={"link-header"}
                to={"/faq"}
                style={{
                  fontWeight: "800",
                  color: "black",

                  margin: 4,
                  textDecoration: "none",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {i18.t("navbar.faq")}
              </Link>
              <Link
                rel={"canonical"}
                onClick={() => setMobileOpen(!mobileOpen)}
                className={"link-header"}
                to={"/foretag"}
                style={{
                  fontWeight: "800",
                  color: "black",

                  margin: 4,
                  textDecoration: "none",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {i18.t("navbar.companies")}
              </Link>
              {getAuth().currentUser !== null ? (
                <Link
                  rel={"canonical"}
                  onClick={() => setMobileOpen(!mobileOpen)}
                  to={"/admin"}
                  style={{
                    color: "yellow",
                    fontWeight: "800",
                    margin: 4,
                    textDecoration: "none",
                  }}
                >
                  ADMIN
                </Link>
              ) : (
                ""
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        elevation={0}
        component="nav"
        position={"absolute"}
        sx={{
          paddingRight: { xs: 1, xl: "20vw" },
          paddingLeft: { xs: 1, xl: "20vw" },
          backgroundColor: "transparent",
        }}
      >
        <Toolbar style={{ padding: 0, margin: 0 }}>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              color: "black",
              alignContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Link
              to={"/"}
              onClick={() => {
                if (location.pathname === "/") navigate(0);
              }}
            >
              <img alt={"Halkan.se"} src={halkanLogo} style={{ width: 150 }} />
            </Link>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: 1,
              alignContent: "flex-end",
              alignItems: "center",
            }}
          >
            <LangMen />
            <Link
              rel={"canonical"}
              className={"link-header"}
              color={"success"}
              to={"/boka"}
              style={{ ...styles.link, color: "green" }}
            >
              {i18.t("navbar.book")}
            </Link>

            <Link
              rel={"canonical"}
              className={"link-header"}
              to={"/kontakta"}
              style={styles.link}
            >
              {i18.t("navbar.contact")}
            </Link>
            <Link
              rel={"canonical"}
              className={"link-header"}
              to={"/faq"}
              style={styles.link}
            >
              {i18.t("navbar.faq")}
            </Link>
            <Link
              rel={"canonical"}
              className={"link-header"}
              to={"/foretag"}
              style={styles.link}
            >
              {i18.t("navbar.companies")}
            </Link>
            {getAuth().currentUser !== null ? (
              <Link
                to={"/admin"}
                style={{
                  color: "yellow",
                  fontWeight: "800",
                  margin: 4,
                  textDecoration: "none",
                }}
              >
                ADMIN
              </Link>
            ) : (
              ""
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

function LangMen() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [lang, setLang] = useState(
    i18.language.includes("-") ? i18.language.split("-")[0] : i18.language
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const captlize = (lang) => {
    return lang[0].toUpperCase() + lang.substring(1);
  };

  return (
    <div>
      <Button id="basic-button" onClick={handleClick}>
        <span style={{ color: "black" }}>
          <LanguageIcon />{" "}
        </span>
        <span style={{ color: "black" }}>{captlize(lang)} </span>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            i18.changeLanguage("sv");
            setLang("Sv");

            handleClose();
          }}
        >
          Svenska
        </MenuItem>
        <MenuItem
          onClick={() => {
            i18.changeLanguage("en");
            setLang("En");
            handleClose();
          }}
        >
          English
        </MenuItem>
      </Menu>
    </div>
  );
}

export default Header;
